














































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import BaseForm from '@/components/forms/BaseForm.vue'
import BaseInput from '@/components/common/BaseInput.vue'
import CustomSelect from '@/components/common/CustomSelect.vue'
import MediaUploader from '@/components/common/MediaUploader.vue'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import { Action, Getter } from 'vuex-class'
import { FileDto, FileRequest, SimpleServerResponse } from '@/store/types'
import Rights from '@/common/rights'
import Unescape from '@/common/unescape'
import { Form, KeyValue, MediaFile } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import {
  DELETE_PHOTO,
  GET_PHOTO,
  SAVE_PHOTO
} from '@/store/user/actions.type'
import { GET_ALL_COMPETENCES } from '@/store/kode/actions'
import PhotoMutation from '@/common/getPhotoMutation'
import { hasRight } from '@/utils/rights'
import Enums from '@/common/Enums'
import { MentorData } from '@/store/mentor/types'
import { GET_MENTOR_BASE_DATA, SET_MENTOR_BASE_DATA } from '@/store/mentor/actions.type'
import {
  getAllBaseCompetencesAsKeyValue,
  getBaseCompetenceObjectArray,
  getCompetenceObjectArray,
  getCompetenceValueAsStringArray
} from '@/common/globals'

@Component({
  components: { BaseForm, BaseInput, CustomSelect, MediaUploader, SubmitSuccess }
})
export default class MentorProfile extends Vue {
  @Action(GET_ALL_COMPETENCES)
  public getAllCompetences: () => Promise<Array<string>>

  @Action(GET_MENTOR_BASE_DATA)
  public getBaseInformation: () => Promise<MentorData>

  @Action(SET_MENTOR_BASE_DATA)
  public setBaseInformation: (data: MentorData) => Promise<void>

  @Action(SAVE_PHOTO)
  public savePhoto: (data: FileRequest) => Promise<SimpleServerResponse>

  @Action(GET_PHOTO)
  public getPhoto: (role: string) => Promise<FileDto>

  @Action(DELETE_PHOTO)
  public deletePhoto: (role: string) => Promise<SimpleServerResponse>

  @Getter('photoFileNameGetter') persistedPhoto: string | undefined

  mentorData: MentorData = {
    description: '',
    firstname: '',
    lastname: '',
    salutation: '',
    title: '',
    email: '',
    photo: {},
    contact: '',
    competences: [],
    baseCompetences: []
  }

  savePressed = false
  competenceOptions: KeyValue[] = []
  photo: MediaFile = {}
  submitSuccess = false
  photoSaved = false

  get salutations (): KeyValue[] {
    return Enums.SALUTATIONS.map(key => {
      return {
        value: key,
        text: this.$i18n.t('personal-information.SALUTATIONS.' + key).toString()
      } as KeyValue
    })
  }

  get titles (): KeyValue[] {
    return Enums.TITLES.map(key => {
      return {
        value: key,
        text: this.$i18n.t('personal-information.TITLES.' + key).toString()
      } as KeyValue
    })
  }

  get fields (): Form[] {
    return [
      {
        id: 'description',
        key: 'description',
        type: InputTypes.TEXTAREA,
        label: this.$i18n.t('mentor.description').toString(),
        md: 12
      },
      {
        id: 'salutation',
        key: 'salutation',
        type: InputTypes.SELECT,
        label: this.$i18n.t('personal-information.salutation').toString(),
        options: this.salutations
      },
      {
        id: 'title',
        key: 'title',
        type: InputTypes.SELECT,
        label: this.$i18n.t('personal-information.title').toString(),
        options: this.titles
      },
      {
        id: 'firstname',
        key: 'firstname',
        type: InputTypes.TEXT,
        required: true,
        label: this.$i18n.t('personal-information.firstName').toString(),
        state: this.savePressed ? !!this.mentorData.firstname : undefined
      },
      {
        id: 'lastname',
        key: 'lastname',
        type: InputTypes.TEXT,
        required: true,
        label: this.$i18n.t('personal-information.lastName').toString(),
        state: this.savePressed ? !!this.mentorData.lastname : undefined
      },
      {
        id: 'competences',
        key: 'competences',
        type: InputTypes.MULTISELECT,
        label: this.$i18n.t('mentor.competence').toString(),
        options: this.competenceOptions,
        md: 12,
        fullWidth: true
      },
      {
        id: 'baseCompetences',
        key: 'baseCompetences',
        type: InputTypes.MULTISELECT,
        label: this.$i18n.t('events.base-competence').toString(),
        options: this.baseCompetenceOptions,
        md: 12,
        fullWidth: true
      },
      {
        id: 'photo',
        key: 'photo',
        type: InputTypes.PHOTO,
        label: this.$i18n.t('mentor.photo').toString()
      },
      {
        id: 'contact',
        key: 'contact',
        type: InputTypes.TEXT,
        label: this.$i18n.t('mentor.contact').toString()
      }
    ]
  }

  get baseCompetenceOptions (): KeyValue[] {
    return getAllBaseCompetencesAsKeyValue()
  }

  get writeable (): boolean {
    return hasRight(Rights.MENTOR_OWN_WRITE)
  }

  get readonly (): boolean {
    return hasRight(Rights.MENTOR_READ)
  }

  mounted (): void {
    this.loadBaseInformation()

    const root = document.getElementById('profile-editor')
    if (root !== null) {
      Unescape.setSoftHyphensInLabels(root)
    }
  }

  saveEvent (): void {
    if (!this.mentorData.firstname || !this.mentorData.firstname.trim()) {
      this.savePressed = true
      return
    }
    if (!this.mentorData.lastname || !this.mentorData.lastname.trim()) {
      this.savePressed = true
      return
    }
    this.save()
  }

  save (): Promise<void> {
    let savedPhotoSuccessfully = false
    let savedProfileSuccessfully = false
    return this.callSavePhoto()
      .then(() => {
        savedPhotoSuccessfully = true
        this.photoSaved = true
      })
      .catch(() => {
        savedPhotoSuccessfully = false
        this.photoSaved = false
      }).finally(() => {
        if (this.mentorData.competences) {
          this.mentorData.competences = getCompetenceValueAsStringArray(this.mentorData.competences as KeyValue[])
        }
        if (this.mentorData.baseCompetences !== undefined) {
          this.mentorData.baseCompetences = getCompetenceValueAsStringArray(this.mentorData.baseCompetences as KeyValue[])
        }
        this.setBaseInformation(this.mentorData).then(() => {
          savedProfileSuccessfully = true
        }).catch(() => {
          savedProfileSuccessfully = false
        }).finally(() => {
          this.submitSuccess = savedPhotoSuccessfully && savedProfileSuccessfully
          this.$root.$emit(
            'alert',
            this.$i18n.t(this.submitSuccess ? 'info.save-success' : 'info.save-failed', {
              what: this.$i18n.t(this.submitSuccess ? 'main-menu-student.mentor-profile' : savedProfileSuccessfully ? 'profile-photo' : 'main-menu-student.mentor-profile')
            }).toString(),
            '',
            !this.submitSuccess
          )
          this.mentorData.competences = this.mentorData.competences ? getCompetenceObjectArray(this.mentorData.competences as string[]) : []
          this.mentorData.baseCompetences = this.mentorData.baseCompetences ? getBaseCompetenceObjectArray(this.mentorData.baseCompetences as string[]) : []
        })
      })
  }

  loadBaseInformation (): void {
    this.getBaseInformation().then((data: MentorData) => {
      this.mentorData = data
      this.mentorData.baseCompetences = this.mentorData.baseCompetences ? getBaseCompetenceObjectArray(this.mentorData.baseCompetences as string[]) : []
      this.mentorData.competences = this.mentorData.competences ? getCompetenceObjectArray(this.mentorData.competences as string[]) : []
      this.getPhoto('MENTOR').then(data => {
        this.$set(this.mentorData, 'photo', { file: data.base64Content })
      }).catch(() => {
        this.mentorData.photo = {}
      })
    })
  }

  created (): void {
    this.getAllCompetences().then(data => {
      this.competenceOptions = getCompetenceObjectArray(data)
    })
  }

  clearPhoto (): void {
    this.$set(this.mentorData, 'photo', { })
  }

  callDeletePhoto (photoMutation: string): Promise<SimpleServerResponse> {
    if (photoMutation === 'CHANGED' || photoMutation === 'DELETED') {
      return this.deletePhoto('MENTOR')
    } else {
      return Promise.resolve({ message: 'ok', requestStatus: '200' }) as Promise<SimpleServerResponse>
    }
  }

  callSavePhoto (): Promise<SimpleServerResponse> {
    const photoMutation = PhotoMutation.getPhotoMutation(this.persistedPhoto, this.mentorData.photo)
    return this.callDeletePhoto(photoMutation).then(() => {
      if (photoMutation === 'CHANGED' || photoMutation === 'ADDED') {
        if (this.mentorData.photo && this.mentorData.photo.base64ImageData !== undefined && this.mentorData.photo.fileForUpload !== undefined) {
          const file: FileDto = {
            base64Content: this.mentorData.photo.base64ImageData,
            filename: this.mentorData.photo.fileForUpload.name
          }
          const dto: FileRequest = {
            role: 'MENTOR',
            file: file
          }

          return this.savePhoto(dto)
        } else {
          return Promise.reject(Error('image not available'))
        }
      } else {
        return Promise.resolve({ message: 'ok', requestStatus: '200' }) as Promise<SimpleServerResponse>
      }
    }).catch((error) => {
      return Promise.reject(error) as Promise<SimpleServerResponse>
    }) as Promise<SimpleServerResponse>
  }

  closeInfo (event: Event): void {
    event.preventDefault()
  }
}
