import { MediaFile } from '@/store/commonFormInput/types'

function getPhotoMutation (persistedFile: string | undefined, newFile: MediaFile | undefined): string {
  if (persistedFile === undefined && newFile && newFile.file !== undefined) {
    return 'ADDED'
  }

  if (persistedFile !== undefined && newFile !== undefined) {
    if (newFile.fileForUpload !== undefined && persistedFile !== newFile.fileForUpload.name) {
      return 'CHANGED'
    }

    if (newFile.file === undefined) {
      return 'DELETED'
    }
  }

  return ''
}

export default { getPhotoMutation }
